<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <div class="table-operator">
      <a-button v-action:add type="primary" @click="handleAdd">添加路线</a-button>
      <SearchForm
          ref="SearchForm"
          :addressList="addressList"
          :goodsList="goods_list"
          @handleSubmit="handleSeachSubmit"
      />
    </div>
    <s-table
        ref="table"
        rowKey="waybill_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
    >
      <div slot="address_list" slot-scope="text, item">
        <a-tooltip>
          <template slot="title">
            <div
                v-for="(i,n) in text"><b>{{ n + 1 }}</b>.{{ i.startAddress.address_name }} / {{
                i.endAddress.address_name
              }}
            </div>
          </template>
          <div
              v-for="(i,n) in text">{{ i.startAddress.address_name }} / {{
              i.endAddress.address_name
            }}
          </div>
        </a-tooltip>
      </div>
      <div slot="goods_list" slot-scope="text, item">
        <div
            v-for="(i,n) in text">{{ i.goods.goods_name }}
        </div>
      </div>
      <div slot="commission" slot-scope="text, item">
        <div v-for="i2 in item.commission_list">
          <a-tag>
            品名：{{ i2.goods ? i2.goods.goods_name : '-' }} /
            驾驶员：<span style="color: rgb(208, 5, 5)">￥</span>{{ i2.driver_commission }} /
            押运员：<span style="color: rgb(208, 5, 5)">￥</span>{{ i2.supercargo_commission }}
          </a-tag>
        </div>
      </div>
      <div slot="price" slot-scope="text, item">
        <span style="color: rgb(208, 5, 5)">￥</span>{{ item.price }}元/吨<br>
        <span style="color: rgb(208, 5, 5)">￥</span>{{ item.price_v }}元/车
      </div>
      <div slot="notax_price" slot-scope="text, item">
        <span style="color: rgb(208, 5, 5)">￥</span>{{ item.notax_price }}元/吨<br>
        <span style="color: rgb(208, 5, 5)">￥</span>{{ item.notax_price_v }}元/车
      </div>
      <div slot="status" slot-scope="text, item">
        <a-tag color="green" v-if="text===0">启用</a-tag>
        <a-tag color="red" v-else>停用</a-tag>
      </div>
      <div class="actions" slot="action" slot-scope="text, item">
        <!--        <a v-action:custom @click="handleCustom(item)">所属客户</a>-->
        <a v-action:edit @click="handleEdit(item)">编辑</a>
        <a-popconfirm
            v-action:delete
            title="确认删除当前记录？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(item)"
        >
          <a href="#">删除</a>
        </a-popconfirm>
      </div>
    </s-table>
    <SaveForm
        :addressList="addressList"
        :goods_list="goods_list"
        :customer_list="customer_list"
        ref="SaveForm"
        @handleSubmit="handleRefresh"
    />
    <AffiliationCustom
        ref="AffiliationCustom"
        @handleSubmit="handleRefresh"
    />
  </a-card>
  <!--  </a-modal>-->
</template>
<script>
import * as Api from '@/api/data/waybill'
import * as addressApi from '@/api/data/address'
import {STable} from "@/components";
import SearchForm from "./modules/SearchForm.vue";
import SaveForm from "./modules/SaveForm.vue";
import AffiliationCustom from "./modules/AffiliationCustom.vue";
import * as goodsApi from "@/api/data/goodsName";
import * as customerApi from "@/api/data/customer";

export default {
  name: "Waybill",
  components: {SearchForm, SaveForm, STable, AffiliationCustom},
  data() {
    return {
      // 标题
      title: '',
      // 查询参数
      queryParam: {},
      // modal(对话框)是否可见
      visible: false,

      loading: false,

      isLoading: false,

      // 地址列表
      addressList: [],

      // 品名列表
      goods_list: [],
      customer_list: [],

      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'waybill_id',
        //   align: 'center',
        //   width: 60,
        // },
        {
          title: '起始地点 / 到达终点',
          dataIndex: 'address_list',
          align: 'center',
          scopedSlots: {customRender: 'address_list'},
          width: 260
        },
        // {
        //   title: '装货品名',
        //   dataIndex: 'goods_list',
        //   align: 'center',
        //   scopedSlots: {customRender: 'goods_list'},
        //   width: 260
        // },
        // {
        //   title: '含税运输单价',
        //   dataIndex: 'price',
        //   align: 'center',
        //   scopedSlots: {customRender: 'price'}
        // },
        // {
        //   title: '不含税运输单价',
        //   dataIndex: 'notax_price',
        //   align: 'center',
        //   scopedSlots: {customRender: 'notax_price'}
        // },

        {
          title: '人员提成',
          dataIndex: 'commission',
          align: 'center',
          scopedSlots: {customRender: 'commission'}
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          scopedSlots: {customRender: 'status'},
          width: 60
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: {customRender: 'action'}
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  mounted() {
    this.getAddress()
    this.getGoods()
    this.getCustomerApi()
  },
  methods: {
    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 查看客户列表
     */
    handleCustom(item) {
      this.$refs.AffiliationCustom.open(item)
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      Api.deleted({waybillId: item['waybill_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 获取地址记录
     */
    async getAddress() {
      const {data: {list}} = await addressApi.getSelectList()
      this.addressList = list
    },

    /**
     * 获取货物品名
     */
    async getGoods() {
      const {data: {list}} = await goodsApi.getSelectList()
      this.goods_list = list
    },

    async getCustomerApi() {
      const {data: {list}} = await customerApi.getSelectList()
      this.customer_list = list
    },


    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
